import {useConfig} from "../../../Services/ConfigContext";
import "./BlogItem.css"
import React from 'react';


const BlogItem = ({data}) => {

    const config = useConfig();


    return (<article className={"blog-item-article"}>
        <div className="post_featured mb-0 position-relative">
            <img src={config.imagesPath + data.bannerImage} className={"blog-item-img"} alt={data.title}/>

            <div className="blog-item-info post_info">
                <div className={"row"}>
                    <div className="post_category mb-2">
                        <div className="post_meta">
                            <span className="post-category">{data.categoryTitle}</span>
                        </div>
                    </div>
                    <h5 className="post_title entry-title"><a
                        href={data.url.startsWith('http') ? data.url : "/blog"+data.url} >{data.title}</a></h5>
                    <div className="post_meta">
                        <span className="post_author_by color-white">By <b>{data.creator}</b></span>
                        <span className="post_meta_item post_date color-white mx-3">{data.date}</span>
                    </div>
                </div>
            </div>
        </div>
    </article>)
}

export default BlogItem